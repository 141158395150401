import React from 'react'
import { connect } from 'react-redux'
import { addProductToCart, AddProduct } from '../state/app'
import { Layout } from '../components/layout'
import SEO from '../components/seo'
import { Dispatch } from 'redux'
import { graphql } from 'gatsby'
import { getProductPrice } from '../services/actions'
import {
  Typography,
  Container,
  Grid,
  Divider,
  withStyles,
  Radio,
  Box,
  BoxProps,
  Button,
  DividerProps,
} from '@material-ui/core'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface Props {
  data: any
  dispatch: Dispatch
}
interface StateToProps {
  cart: any
  products: any
}

interface DispatchToProps {
  addToCart: (params: AddProduct) => void
}

const SizeBox = withStyles({
  root: {
    border: '1px solid #000',
    height: 35,
    width: 35,
    '&$hover': {
      color: 'orange',
      backgroundColor: 'orange',
      height: 100,
      width: 100,
    },
  },
})((props: BoxProps) => <Box {...props} />)

const StyledDivider = withStyles({
  root: {
    margin: '15px',
  },
})((props: DividerProps) => <Divider {...props} />)

const ProductDetails: React.FC<StateToProps & DispatchToProps & Props> = ({
  data,
  cart,
  addToCart,
}) => {
  const [selectedProduct, updateSelectedProduct] = React.useState({
    priceId: '',
    size: '',
    description: '',
    price: 0,
    image: '',
  })
  const { contentfulProduct } = data
  const { products } = contentfulProduct.stripeProducts

  const notify = () => toast('Added to Cart')

  React.useEffect(() => {
    console.log('cart', cart)
    console.log('products', products)
  }, [cart, products, data])

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    description: string
  ) => {
    console.log('event.target.id', event.target.id)
    console.log('event.target.value', event.target.value)
    const { id, value } = event.target
    updateSelectedProduct({
      description,
      priceId: id,
      size: value,
      price: contentfulProduct.price,
      image: contentfulProduct.image.fixed.src,
    })
  }

  const handleAddToCart = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('event', event)
    addToCart({ ...selectedProduct, quantity: 1 })
    notify()
  }

  return (
    <Layout>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <SEO
        title={data.contentfulProduct.name}
        keywords={[`wsh entertainment`, `fashion`, `clothing`]}
      />
      <Container>
        {data.contentfulProduct.productMorePhotos === null ? (
          <div className="no-image">No Image</div>
        ) : (
          <Grid
            container
            style={{ paddingTop: '100px', paddingBottom: '50px' }}
          >
            <Grid item md={6}>
              <img
                src={contentfulProduct.image.fixed.src}
                alt={contentfulProduct.name}
              />
            </Grid>
            <Grid item md={5} style={{ marginLeft: 30 }}>
              <Typography gutterBottom variant={'h1'}>
                {contentfulProduct.name}
              </Typography>
              <Typography gutterBottom variant={'h1'}>
                ${contentfulProduct.price}
              </Typography>
              <StyledDivider />
              <Typography
                variant={'h3'}
                dangerouslySetInnerHTML={{
                  __html: contentfulProduct.details.childMarkdownRemark.html,
                }}
              />
              <StyledDivider />
              <Typography variant={'h3'} display={'block'}>
                Size
              </Typography>
              {products !== undefined &&
                products.map((p: any) => {
                  console.log('p', p)
                  console.log('selectedProduct', selectedProduct)
                  return (
                    <Radio
                      id={p.priceId}
                      checked={selectedProduct.size === p.size}
                      onChange={e =>
                        handleChange(e, data.contentfulProduct.name)
                      }
                      value={p.size}
                      inputProps={{ 'aria-label': p.size }}
                      checkedIcon={
                        <SizeBox style={{ border: '2px solid #000' }}>
                          <Typography variant={'caption'}>{p.size}</Typography>
                        </SizeBox>
                      }
                      icon={
                        <SizeBox>
                          <Typography variant={'caption'}>{p.size}</Typography>
                        </SizeBox>
                      }
                    />
                  )
                })}

              <StyledDivider />
              {/* TODO: configure quantity */}
              {/* <Typography variant={'h3'} display={'block'}>
                Quantity*
              </Typography> */}
              <Button
                disabled={selectedProduct.priceId === ''}
                onClick={handleAddToCart}
              >
                Add to Cart
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </Layout>
  )
}

export default connect<StateToProps, DispatchToProps, {}, {}>(
  (state: any) => ({
    cart: state.app.cart,
    products: state.app.products,
  }),
  (dispatch: any) => ({
    addToCart: (params: AddProduct) => dispatch(addProductToCart(params)),
  })
)(ProductDetails)

export const query = graphql`
  query ProductDetailsQuery($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      id
      name
      slug
      stripeProducts {
        products {
          priceId
          size
        }
      }
      image {
        fixed(width: 1000, height: 1000) {
          width
          height
          src
          srcSet
        }
      }
      price
      details {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
